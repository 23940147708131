<template>
  <div>
    <div id="content" class="col-md-12">
      <div class="products-category">
        <!-- <h3 class="title-category ">Accessories</h3> -->
        <div class="category-desc">
          <div class="row" style="margin-bottom: 15px">
            <div class="col-sm-12">
              <div class="banners">
                <div>
                  <a href="#"
                    ><img
                      style="width: 100%"
                      src="assets/image/catalog/banners/banner.png"
                      v-if="image === ''"
                      alt="img cate" /><br
                  /></a>
                  <!-- <a href="#"><img style="width:100%; height: 500px;" else :src="image" alt="img category"><br></a> -->
                </div>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-sm-12">
              <div class="col-md-12" id="preview-area" v-if="blockLoading">
                <div class="row text-center">
                  <div class="sk-chase">
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                    <div class="sk-chase-dot"></div>
                  </div>
                </div>
              </div>
              <div v-for="(item, index) in blocks" :key="index">
                <component
                  :is="item.blockcompnent"
                  v-bind:data="item"
                ></component>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- Modal -->
    <div
      class="modal fade"
      id="productModalCenter"
      tabindex="-1"
      role="dialog"
      aria-labelledby="productModalCenterTitle"
      aria-hidden="true"
    >
      <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div class="modal-content">
          <div class="modal-header"  style="display: flex; justify-content: space-between; align-items: end;">
            <h5 class="modal-title" id="productModalLongTitle">
              Product Quick View
            </h5>
            <button
              type="button"
              class="close"
              data-dismiss="modal"
              aria-label="Close"
              style="margin-top: -20px"
            >
              <span  style="font-size: 30px; font-weight: initial; color: black; color: black !important;" aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <ProductModalContent v-bind:pid="this.product_id" />
          </div>
          <div class="modal-footer">
            <button
              type="button"
              class="btn"
              data-dismiss="modal"
              style="background-color: white !important; border: 1px solid black !important; border-radius: 5px; color: black;"
            >
              Close
            </button>
            <!-- <button type="button" class="btn btn-primary">Save changes</button> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// SUB BLOCK COMPONENTS
import ProductTile from "@/components/product/product_tile.vue";

// BANNER COMPONENTS
import Banner3 from "@/components/banners/Banner3.vue";
import Banner4 from "@/components/banners/Banner4.vue";
import StaticCates from "@/components/banners/StaticCates.vue";

// BLOCK COMPONENTS
import ProductSliderBlock from "@/components/product/ProductSliderBlock.vue";
import ProductSliderGridBlock from "@/components/product/ProductSliderGridBlock.vue";

import ProductModalContent from "@/components/product/product_modal.vue";

import mixinProduct from "@/mixins/buyer/APIProduct";
import mixinCategory from "@/mixins/merchant/APICategory.js";
import mixinCommon from "@/mixins/APICommon";

export default {
  components: {
    ProductSliderBlock,
    ProductSliderGridBlock,
    ProductModalContent,
    ProductTile,
    Banner3,
    Banner4,
    StaticCates,
  },
  mixins: [mixinProduct, mixinCategory, mixinCommon],
  data() {
    return {
      breadcrumb: [
        {
          text: "Product List",
          href: "/productlist",
          active: false,
        },
      ],
      cID: "",
      product_id: "",
      blocks: [],
      image: "",
      showmode: "list",
      blockLoading: true,
    };
  },
  computed: {
    categoryId() {
      let url = this.$route.query.id;
      return url;
    },
  },
  watch: {
    $route(to, from) {
      this.getCategoryDetail();
      this.$forceUpdate();
      this.callFucntionsByOrder();
    },
    "$route.query.id"() {
      this.$forceUpdate();
      this.callFucntionsByOrder();
    },
  },
  mounted: function () {
    this.handleBreadcrumb();

    this.display("grid");
  },
  created() {
    this.initiateList();
    this.callFucntionsByOrder();
    this.getCategoryDetail();
    // this.handleGetStores();
  },
  methods: {
    getURLcID() {
      let url = window.location.href.split("id=");
      this.cID = url[1];
      return url[1];
    },
    callFucntionsByOrder: async function () {
      this.getURLcID();
      await this.getProductsByID();
    },
    handleBreadcrumb: function () {
      this.$store.commit("setBreadcrumbSwitchState", {
        action: null,
        payload: this.breadcrumb,
      });
    },
    initiateList() {
      let self = this;
      $(".list-view .btn").each(function () {
        $(this).siblings(".btn").removeClass("active");
      });
    },
    getCategoryDetail: async function () {
      try {
        let res = await this.getCategoryDetailById(this.categoryId);
        if (res && res.image != undefined) {
          this.image = res.image;
        }
      } catch (error) {
        throw error;
      }
    },
    display(view) {
      $(".products-list").removeClass("list grid").addClass(view);
      $(".list-view .btn").removeClass("active");
      if (view == "list") {
        //$('.products-list .product-layout').addClass('col-lg-12');
        // $('.products-list .product-layout .left-block').addClass('col-md-4');
        // $('.products-list .product-layout .right-block').addClass('col-md-8');
        $(".products-list .product-layout .item-desc").removeClass("hidden");
        $(".products-list .product-layout .list-block").removeClass("hidden");
        $(".products-list .product-layout .button-group").addClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "list");
      } else {
        //$('.products-list .product-layout').removeClass('col-lg-12');
        //$('.products-list .product-layout .left-block').removeClass('col-md-4');
        //$('.products-list .product-layout .right-block').removeClass('col-md-8');
        $(".products-list .product-layout .item-desc").addClass("hidden");
        $(".products-list .product-layout .list-block").addClass("hidden");
        $(".products-list .product-layout .button-group").removeClass("hidden");
        $(".list-view ." + view).addClass("active");
        $(".list-view .btn").removeClass("active");
        $.cookie("display", "grid");
      }

      this.showmode = view;
    },
    getProductsByID: async function (currentpage) {
      this.blockLoading = true;
      try {
        this.blocks = [];
        let responseByCatID = await this.getProductListByCategoryID(this.cID);
        let blockdata = [];
        let brands = [];
        let brandarray = [];
        for (let index = 0; index < responseByCatID.length; index++) {
          var barray = brands.filter(function (b) {
            return b.title == responseByCatID[index].brand.brand_name;
          });
          if (barray.length === 0) {
            let brandimg =
              "https://cdn.dribbble.com/users/261302/screenshots/1361907/logoplaceholder.png";
            if (responseByCatID[index].brand.logo_img) {
              brandimg = responseByCatID[index].brand.logo_img;
            }

            brands.push({
              blockdatacomponent: "ProductTileBrand",
              item_id: responseByCatID[index].brand._id,
              cid: this.cID,
              url: "/product",
              imgpath: [brandimg],
              title: responseByCatID[index].brand.brand_name,
              rounded: true,
            });
          }
        }

        for (let index = 0; index < brands.length; index++) {
          let brandname = brands[index].title;
          let brandid = brands[index].item_id;
          let branddata = [];
          for (let index2 = 0; index2 < responseByCatID.length; index2++) {
            if (brandname === responseByCatID[index2].brand.brand_name) {
              let imgs = [];
              let imagearray = responseByCatID[index2].show_image;
              for (let index3 = 0; index3 < imagearray.length; index3++) {
                let imgpath = imagearray[index3].image;
                // let is1 = imgpath.split("upload/")[0];
                // let is4 = imgpath.split("upload/")[1];
                // let is2 = "upload/q_auto,f_auto/w_300,h_300,c_fill/";
                // let is3 = is4.split("/")[1];
                // imgpath = is1 + is2 + is4;
                imgs.push(imgpath);
              }

              if (responseByCatID[index2].rate.toString().includes(".")) {
                let value1 = responseByCatID[index2].rate.toString().split(".");
                responseByCatID[index2].rateAvg = value1[0];
                if (value1[1] === "5" || value1[1] > "5") {
                  responseByCatID[index2].rateAvg =
                    parseInt(responseByCatID[index2].rateAvg) + 1;
                }
              } else {
                responseByCatID[index2].rateAvg = responseByCatID[index2].rate;
              }

              let oldprice = "";
              let newprice = "";
              if (
                responseByCatID[index2].simple_products[0].special_price != 0 &&
                responseByCatID[index2].simple_products[0].special_price != ""
              ) {
                newprice =
                  responseByCatID[index2].simple_products[0].special_price;
                oldprice = responseByCatID[index2].simple_products[0].price;
              } else {
                newprice = responseByCatID[index2].simple_products[0].price;
                oldprice = "";
              }
              // console.log(productlist[index]._id);
              branddata.push({
                blockdatacomponent: "ProductTile",
                discountprecentage: "",
                imgpath: imgs,
                wishlit: responseByCatID[index2].wishlit,
                item_id: responseByCatID[index2]._id,
                prediscount: oldprice,
                price: newprice,
                product_name: responseByCatID[index2].product_name,
                primary_product: responseByCatID[index2].primary_product,
                rating: responseByCatID[index2].rateAvg,
                title: responseByCatID[index2].product_name,
                url: "/product/" + responseByCatID[index2]._id,
              });

              // branddata.push(responseByCatID[index2]);
            }
          }

          blockdata.push({
            title: brandname,
            blockcompnent: "ProductSliderBlock",
            blockdata: branddata,
            showBlock: true,
          });

          brandarray.push({
            blockdatacomponent: "ProductTileBrand",
            item_id: brandid,
            url: "/product",
            imgpath: [
              "https://www.apple.com/ac/structured-data/images/knowledge_graph_logo.png?201809210816",
            ],
            title: brandname,
            rounded: true,
            cid: this.cID,
          });
        }

        this.blocks = blockdata;

        this.blocks
          .unshift

          // {
          //   title: "CUSTOM DEALS",
          //   blockcompnent: "Banner4",
          //   blockdata: [{
          //     class: "item1",
          //     item_id: "3123123312sdsasd232",
          //     url: "/product",
          //     imgpath:"assets/image/catalog/banners/bn1.jpg"
          //   },{
          //     class: "item2",
          //     item_id: "3123123312sdsasd232",
          //     url: "/product",
          //     imgpath:"assets/image/catalog/banners/bn2.jpg"
          //   }]
          // },
          ();

        this.blocks.unshift({
          title: "",
          blockcompnent: "ProductSliderBlock",
          blockdata: brands,
          showBlock: true,
        });

        this.blockLoading = false;
      } catch (error) {
        this.blockLoading = false;
      }
    },
    pushToProduct() {
      this.$router.push("/product");
    },
    parentModalTrigger(pid) {
      this.product_id = pid;

      this.inputNumValue = 1;
      this.isDisable = true;
      this.idObj = {
        ids: [],
      };

      //    this.setProductInfo();
      $("#productModalCenter").modal("show");
    },
  },
};
</script>
<style>
.mb-0 {
  margin-bottom: 0px !important;
}
.mb-5 {
  margin-bottom: 5px !important;
}
.m0p0 {
  margin: 0px !important;
  padding: 0px !important;
}

#productModalLongTitle {
  font-size: 18px;
  color: black;
  width: fit-content;
}

.module div.modtitle {
  border-bottom: 2px solid #eee;
  display: inline-block;
  width: 100%;
  position: relative;
  margin-top: 0;
  margin-bottom: 10px;
}

.module div.modtitle span {
  font-size: 20px;
  color: #333;
  font-weight: 700;
  float: left;
  text-transform: uppercase;
  margin-bottom: 4px;
  background-color: transparent;
  padding: 0;
  padding-bottom: 4px;
  padding-top: 2px;
  bottom: 0;
}

.module div.modtitle:after {
  position: absolute;
  content: "";
  width: 110px;
  height: 2px;
  background-color: #ffd338;
  margin-top: 32px;
  left: 0;
}
</style>